<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>User</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="First Name *"
                    v-model="user.firstName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the First Name'"
                    :is-valid="
                      $v.user.firstName.$dirty
                        ? !$v.user.firstName.$error
                        : null
                    "
                    @blur="$v.user.firstName.$touch()"
                  />
                  <CInput
                    label="Last Name *"
                    v-model="user.lastName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Last Name'"
                    :is-valid="
                      $v.user.lastName.$dirty ? !$v.user.lastName.$error : null
                    "
                    @blur="$v.user.lastName.$touch()"
                  />

                  <CInput
                    label="Mobile No * "
                    v-model="user.mobile"
                    horizontal
                    type="number"
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Mobile No'"
                    :is-valid="
                      $v.user.mobile.$dirty ? !$v.user.mobile.$error : null
                    "
                    @blur="$v.user.mobile.$touch()"
                  />
                  <CInput
                    label="Email * "
                    v-model="user.email"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Email'"
                    :is-valid="
                      $v.user.email.$dirty ? !$v.user.email.$error : null
                    "
                    @blur="$v.user.email.$touch()"
                  />
                  <div v-if="mode.newMode">
                    <MultiSelect
                      label="Groups"
                      :options="allGroups"
                      @input="updateGroups"
                      :hide-selected="false"
                      :searchable="true"
                      optionLabel="groupName"
                      :disabled="mode.viewMode"
                      track-by="groupId"
                      :track-ids.sync="groupId"
                      idParamName="groupId"
                      :itemTypeisString="false"
                      invalidFeedback="Select atleast one Group"
                    ></MultiSelect>
                    <MultiSelect
                      label="Sub Groups"
                      :options="selectedSubGroups"
                      @input="updatedSubGroups"
                      :hide-selected="false"
                      :searchable="true"
                      optionLabel="subGroupName"
                      :disabled="mode.viewMode"
                      track-by="subGroupId"
                      :track-ids.sync="subgroupId"
                      idParamName="subGroupId"
                      :itemTypeisString="false"
                      invalidFeedback="Select atleast one Sub Group"
                    ></MultiSelect>
                  </div>
                  <div v-if="mode.newMode">
                    <MultiSelect
                      label="Departments"
                      :options="selectedDepartments"
                      :hide-selected="false"
                      :searchable="true"
                      optionLabel="departmentName"
                      :disabled="mode.viewMode"
                      track-by="departmentId"
                      :track-ids.sync="user.departmentId"
                      idParamName="departmentId"
                      :itemTypeisString="false"
                      invalidFeedback="Select atleast one Departments"
                    ></MultiSelect>
                  </div>
                  <div v-if="mode.viewMode">
                    <MultiSelect
                      label="Departments"
                      :options="allDepartments"
                      :hide-selected="false"
                      :searchable="true"
                      optionLabel="departmentName"
                      :disabled="mode.viewMode"
                      track-by="departmentId"
                      :track-ids.sync="user.departmentId"
                      idParamName="departmentId"
                      :itemTypeisString="false"
                      invalidFeedback="Select atleast one Departments"
                    ></MultiSelect>
                  </div>
                  <CInput
                    label="Designation"
                    v-model="user.designation"
                    horizontal
                    placeholder="Admin"
                    disabled
                  />
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" /> Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="Saveuser()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import MultiSelect from "../../containers/MultiSelect";
import {
  required,
  email,
  mobile,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "CMSUser",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      uid: "",
      user: {
        uid: "0",
        firstName: "",
        lastName: "",
        authority: "ROLE_ADMIN",
        authorityType: "4",
        email: "",
        mobile: "",
        departmentId: "",
        designation: "Admin",
      },
      groupId: "",
      subgroupId: "",
      allTopics: [],
      allOfficers: [],
      allGroups: [],
      selectedGroups: [],
      allSubGroups: [],
      selectedSubGroups: [],
      updateSubGroups: [],
      selectedDepartments: [],
      allDepartments: [],
      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    user: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },

      email: {
        required,
        email,
      },
      mobile: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.departmentId = this.$route.params.departmentId;
      this.getGroups();
      this.getSubGroups();
      this.getDepartments();
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.userId = this.$route.params.uid;
      this.departmentId = this.$route.params.departmentId;
      this.getUser();
      this.getGroups();
      this.getSubGroups();
      this.getDepartments();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.userId = this.$route.params.uid;
      this.departmentId = this.$route.params.departmentId;
      this.getUser();
      this.getGroups();
      this.getSubGroups();
      this.getDepartments();
    }
  },
  components: { datetime, MultiSelect },

  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({ name: "CMSUsers" });
    },
    updateGroups(data) {
      var self = this;
      self.groupId = data.groupId;
      console.log(self.groupId);
      self.groupId = data.groupId;
      var model = self.allSubGroups.filter(function (myId) {
        return data.groupId == myId.groupId;
      });
      self.model = [];
      console.log(model);
      self.selectedSubGroups = model;
    },

    updatedSubGroups(data) {
      var self = this;
      self.subGroupId = data.subGroupId;
      console.log(self.subGroupId);
      self.subGroupId = data.subGroupId;
      var modeldepartments = self.allDepartments.filter(function (mydepId) {
        return data.subGroupId == mydepId.subGroupId;
      });
      self.modeldepartments = [];
      console.log(modeldepartments);

      self.selectedDepartments = modeldepartments;
    },
    getGroups() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/groups"
      )
        .then((response) => response.json())
        .then((data) => (this.allGroups = data));
    },
    getSubGroups() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/subGroups"
      )
        .then((response) => response.json())
        .then((data) => (this.allSubGroups = data));
    },
    getDepartments() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/all/departments"
      )
        .then((response) => response.json())
        .then((data) => (this.allDepartments = data));
    },

    Saveuser() {
      //    if(this.user.mobile!=null ){
      //        console.log("error user.mobile ")

      //      var mobile = this.user.mobile;
      //     if(!(/^[7-9][0-9]{9}$/.test(mobile))){
      //         errorFlag = true;
      //        this.errors.push({"field":"mobile","error":"Please Enter Valid mobile"});
      //       console.log("errors", this.errors)
      //        console.log("errorFlag", errorFlag)
      //        return ;
      //  }
      //    }
      console.log("Saveuser(): this.departmentId", this.user.departmentId);
      // var urlForSaveUser =
      //   process.env.VUE_APP_API_HOST +
      //   "/emapp/web/user/save/" +
      //   this.user.departmentId;
      // console.log("Saveuser(): url", urlForSaveUser);
      console.log("this.users", JSON.stringify(this.user));
      this.$v.user.$touch();
      if (this.$v.user.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.user));
      } else {
        // fetch(urlForSaveUser, {
        //   method: "POST",
        //   headers: {
        //     "Content-type": "application/json",
        //   },
        //   body: JSON.stringify(this.user),
        // })
        this.apiPostSecure(
          process.env.VUE_APP_API_HOST +
            "/emapp/web/secure/user/save/" +
            this.user.departmentId,
          this.user
        ).then((user) => {
          console.log(JSON.stringify(user));

          window.history.back();
        });
      }
    },
    deleteUser(uid) {
      console.log("delete called with", this.uid);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST + "/emapp/web/secure/delete/user/" + uid
      )
        .then((response) => response.json())
        .then((data) => {
          this.getDepartments();
          this.$nextTick(() => {
            this.$refs.vuetable.refresh();
          });
          window.location.reload();
        });
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/user/" +
          this.userId
      )
        .then((response) => response.json())
        .then((data) => (this.user = data));
    },
  },
  mounted() {
    //this. getRoles();
  },
};
</script>
